import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../../common/Title';
import DataTable from '../../../common/Datatable';

import useAuth from '../../../../hooks/useAuth';
import useAxios from '../../../../hooks/useAxios';
import {
  __cancelSubscriber,
  __resumeSubscriber,
  __suspendSubscriber,
  __restoreSubscriber,
  __getSubscriberDetails,
  __getSubscriberActivityHistory,
} from '../../../../api/account';
import { LINE_DETAIL_LABEL } from '../../../../constants/responseMapping';

const SubscriberDetail = () => {
  const nav = useNavigate();
  const { user } = useAuth();
  const [apiCall, setApiCall] = useState(null);
  const [details, setDetails] = useState(null);
  const [history, setHistory] = useState(null);
  const { subscriber_number } = useParams();
  const [response, call, loading] = useAxios({
    ...__getSubscriberDetails(subscriber_number),
  });
  const [apiLoading, setApiLoading] = useState(false);

  const [statusResponse, statusCall, statusLoading] = useAxios(apiCall);
  const [historyResponse, historyCall, historyLoading] = useAxios({
    ...__getSubscriberActivityHistory(subscriber_number),
  });

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 2,
      sortable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 2,
      valueGetter: params =>
        params.row.timestamp && new Date(params.row.timestamp).toUTCString(),
    },
    {
      field: 'userId',
      headerName: 'User ID',
      flex: 1,
      valueGetter: params => params.row?.user?.id,
    },
  ];

  useEffect(() => {
    call();
    historyCall();
  }, []);

  useEffect(() => {
    if (apiCall) {
      statusCall();
    }
  }, [apiCall]);

  useEffect(() => {
    if (response && response.status) {
      setDetails(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (historyResponse && historyResponse.status) {
      setHistory(historyResponse.data);
    }
  }, [historyResponse]);

  useEffect(() => {
    if (statusResponse && statusResponse.status) {
      setDetails(statusResponse.data);
    }
  }, [statusResponse]);

  if (loading || apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!details || !Object.keys(details).length) {
    return (
      <Title content="Data not available" variant="subtitle2" gutterBottom />
    );
  }

  const request = {
    suspend: __suspendSubscriber(subscriber_number),
    restore: __restoreSubscriber(subscriber_number),
    cancel: __cancelSubscriber(subscriber_number),
    resume: __resumeSubscriber(subscriber_number),
  };

  const changeStatus = status => {
    setApiCall(request[status]);
  };

  const getButtonType = status => {
    switch (status) {
      case 'Active':
        return (
          <>
            <Button
              variant="contained"
              color="error"
              onClick={() => changeStatus('suspend')}
            >
              Suspend Line
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => changeStatus('cancel')}
            >
              Cancel Line
            </Button>
          </>
        );
      case 'Suspended':
        return (
          <>
            <Button
              variant="contained"
              color="success"
              onClick={() => changeStatus('restore')}
            >
              Restore Line
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => changeStatus('cancel')}
            >
              Cancel Line
            </Button>
          </>
        );
      case 'Cancelled':
        return (
          <Button
            variant="contained"
            color="success"
            onClick={() => changeStatus('resume')}
          >
            Resume Line
          </Button>
        );

      case 'Reserved':
      case '':
        return <></>;

      default:
        return 'Update in progress (Refresh after some time)';
    }
  };

  const getSingleUserCodeRow = () => {
    const displayData = details.single_user_code
      ? `${details.single_user_code.code} (${details.single_user_code.name})`
      : details.single_user_code?.code || '-';

    return (
      <TableRow key={'single_user_code'}>
        <TableCell>{LINE_DETAIL_LABEL.single_user_code}</TableCell>
        <TableCell>{displayData}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box>
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
          {user.is_admin && getButtonType(details.status)}
          {user.is_admin &&
            details.status &&
            details.status !== 'Queued' &&
            details.status !== 'Reserved' && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav('change_sim')}
                >
                  Change Sim
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => nav('change_imei')}
                >
                  Change IMEI
                </Button>
              </>
            )}
        </Stack>
        <Box sx={{ m: 2 }}>
          <Divider></Divider>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              {Object.keys(LINE_DETAIL_LABEL).map(key =>
                key !== 'single_user_code' ? (
                  <TableRow key={key}>
                    <TableCell>{LINE_DETAIL_LABEL[key]}</TableCell>
                    <TableCell>{details[key] || '-'}</TableCell>
                  </TableRow>
                ) : (
                  getSingleUserCodeRow()
                )
              )}
              {details.packet_data_profile ? (
                <TableRow>
                  <TableCell>Packet Data Profile</TableCell>
                  <TableCell>{details.packet_data_profile}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
              {details.ip_address ? (
                <TableRow>
                  <TableCell>IP Address</TableCell>
                  <TableCell>{details.ip_address}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
              {details.access_point_name ? (
                <TableRow>
                  <TableCell>Access Point Name</TableCell>
                  <TableCell>{details.access_point_name}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
              <TableRow>
                <TableCell>Cycle Data Usage</TableCell>
                <TableCell>
                  {details.cycle_data_usage || '0'} MB (Last Updated{' '}
                  {details.last_updated
                    ? new Date(details.last_updated).toLocaleString()
                    : ''}
                  )
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
      {history && history.length ? (
        <Box sx={{ mt: 2 }}>
          <Title variant="h5" content={`Activity History`} gutterBottom />
          <DataTable
            loading={loading}
            data={history}
            columns={columns}
            handleRowClick={null}
            filterMode={'client'}
            client={true}
            disableRowSelectionOnClick={true}
          />
        </Box>
      ) : null}
    </>
  );
};

export default SubscriberDetail;
