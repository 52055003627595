import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import SingleUserCodeList from './list/SingleUserCodeList';
import {
  __deleteSingleUserCode,
  __getSingleUserCodes,
} from '../../../api/singleUserCode';

const SingleUserCode = () => {
  const nav = useNavigate();
  const [singleUserCodes, setSingeUserCodes] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [response, call, loading] = useAxios({ ...__getSingleUserCodes });
  const [deleteResponse, deleteCall, deleteLoading] = useAxios({
    ...__deleteSingleUserCode(deleteId),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setSingeUserCodes(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (deleteId) {
      deleteCall();
    }
  }, [deleteId]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.status) {
      call();
      setDeleteId(null);
    }
  }, [deleteResponse]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => nav('create')}
          variant="contained"
          color="success"
        >
          Create
        </Button>
      </Stack>
      <Title variant="h5" content="Single User Code List" gutterBottom />
      {response && !loading && !deleteLoading ? (
        <SingleUserCodeList
          singleUserCodes={singleUserCodes}
          deleteCode={setDeleteId}
        />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default SingleUserCode;
