import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TableContainer from '@mui/material/TableContainer';
import FormHelperText from '@mui/material/FormHelperText';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import {
  __changeSubscriberImei,
  __getSubscriberDetails,
} from '../../../../api/account';

export default function SubscriberChangeImei() {
  const nav = useNavigate();
  let initialData = { service_zip_code: '', imei: '' };
  const [details, setDetails] = useState(null);
  const { subscriber_number } = useParams();
  const [subscriberResponse, subscriberCall, loading] = useAxios({
    ...__getSubscriberDetails(subscriber_number),
  });
  const [formData, setFormData] = useState(null);

  const validationSchema = yup.object({
    imei: yup
      .string('New IMEI')
      .required('New IMEI number required')
      .length(15, 'IMEI number length is 15'),
  });

  const formik = useFormik({
    initialValues: {
      ...initialData,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setFormData(values);
    },
  });

  const [updateResponse, updateCall, updateLoading] = useAxios({
    ...__changeSubscriberImei(subscriber_number),
    data: formData,
  });

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      nav(`/account/${subscriber_number}`);
    }
    setFormData(null);
    formik.setSubmitting(false);
  }, [updateResponse]);

  useEffect(() => {
    if (formData) {
      updateCall();
    }
  }, [formData]);

  useEffect(() => {
    subscriberCall();
  }, []);

  useEffect(() => {
    if (subscriberResponse && subscriberResponse.status) {
      setDetails(subscriberResponse.data);
    }
  }, [subscriberResponse]);

  if (loading || updateLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!details || !Object.keys(details).length) {
    return (
      <Title content="Data not available" variant="subtitle2" gutterBottom />
    );
  }

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Subscriber No.</TableCell>
                <TableCell align="left">SIM</TableCell>
                <TableCell align="left">IMEI</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{details.subscriber_number}</TableCell>
                <TableCell>{details.sim}</TableCell>
                <TableCell>{details.imei}</TableCell>
                <TableCell>{details.status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt="15px">
        <Title content="Add new sim details" variant="subtitle2" gutterBottom />
        <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={5} sx={{ mb: 3 }}>
            <FormControl
              error={
                formik.touched.imei && Boolean(formik.errors.imei)
                  ? true
                  : false
              }
              sx={{ width: '100%' }}
            >
              <InputLabel htmlFor="imei">New IMEI</InputLabel>
              <OutlinedInput
                imei="imei"
                label="New IMEI"
                id="imei"
                value={formik.values.imei}
                onChange={formik.handleChange}
              />
              {formik.touched.imei && Boolean(formik.errors.imei) ? (
                <FormHelperText id="imei-error-text">
                  {formik.errors.imei}
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid justify="flex-end" alignItems="center" container>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              onClick={() =>
                !updateLoading && !formik.isSubmitting && formik.submitForm()
              }
              size="large"
              loading={updateLoading || formik.isSubmitting}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
