import * as React from 'react';

import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Paginator({
  handlePageChange,
  totalPage,
  disabled,
  currentPage,
}) {
  return (
    <Stack spacing={2}>
      <Pagination
        disabled={disabled}
        page={currentPage || 1}
        count={totalPage}
        onChange={(e, page) => handlePageChange(page)}
        renderItem={item => (
          <PaginationItem
            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}
