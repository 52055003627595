const __getUserList = {
  method: 'GET',
  url: 'user/',
};

const __getUserDetails = user_id => ({
  method: 'GET',
  url: `user/${user_id}`,
});

const __createUser = {
  method: 'POST',
  url: 'user/',
};

const __updateUser = user_id => ({
  method: 'PUT',
  url: `user/${user_id}`,
});

export { __createUser, __updateUser, __getUserList, __getUserDetails };
