import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { gridNumberComparator } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Title from '../../../common/Title';
import DataTable from '../../../common/Datatable';

import useAxios from '../../../../hooks/useAxios';
import {
  __getSingleLineUsageOccurrence,
  __singleLineUsageExport,
} from '../../../../api/account';

export default function LineUsageList() {
  const { subscriber_number } = useParams();
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [response, call, loading] = useAxios({
    ...__getSingleLineUsageOccurrence(subscriber_number),
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__singleLineUsageExport(subscriber_number),
  });

  const columns = [
    {
      field: 'usage',
      headerName: 'Data Usage [MB]',
      flex: 1,
      sortable: true,
      valueGetter: params => (params.row.usage / 1024).toFixed(2) || 0,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'date_occurred',
      headerName: 'Date Occurred',
      flex: 1,
      renderCell: params =>
        params.row.date_occurred &&
        new Date(params.row.date_occurred + "Z").toUTCString(),
    },
    {
      field: 'date_reported',
      headerName: 'Date Reported',
      flex: 1,
      renderCell: params =>
        params.row.date_reported &&
        new Date(params.row.date_reported + "Z").toUTCString(),
    },
  ];

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(
        exportResponse.data,
        `line_usage_details-${subscriber_number}.csv`
      );
    }
  }, [exportResponse]);

  return (
    <Box sx={{ width: '100%' }}>
      <Title
        variant="h5"
        content={
          <>
            Subscriber Usage Details:{' '}
            <Button
              sx={{ padding: 0, margin: 0, border: null }}
              variant="text"
              size="large"
              onClick={() => nav(`/account/${subscriber_number}`)}
            >
              {subscriber_number}
            </Button>
          </>
        }
        gutterBottom
      />
      {loading || !response ? (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      ) : !data || !Object.keys(data).length ? (
        <Title content="Data not available" variant="subtitle2" gutterBottom />
      ) : (
        <>
          <Box
            sx={{ width: '100%', mb: 5 }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Subscriber Number</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Cycle Data Usage</TableCell>
                    <TableCell align="left">SIM</TableCell>
                    <TableCell align="left">IMEI</TableCell>
                    <TableCell align="left">Single User Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Button
                        sx={{ padding: 0, margin: 0, border: null }}
                        variant="text"
                        size="large"
                        onClick={() => nav(`/account/${subscriber_number}`)}
                      >
                        {subscriber_number}
                      </Button>
                    </TableCell>

                    <TableCell align="left">{data.line.status}</TableCell>
                    <TableCell align="left">
                      {data.line.cycle_data_usage}
                    </TableCell>
                    <TableCell align="left">{data.line.sim}</TableCell>
                    <TableCell align="left">{data.line.imei}</TableCell>
                    <TableCell align="left">
                      {data.line.single_user_code.name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {data.suspended_info && Object.keys(data.suspended_info).length && (
            <Box
              sx={{ width: '100%', mb: 5 }}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Title
                content="Suspend details"
                variant="subtitle2"
                gutterBottom
              />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Suspended Date</TableCell>
                      <TableCell align="left">Restored Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">
                        {new Date(
                          data.suspended_info.suspended_time
                        ).toUTCString()}
                      </TableCell>
                      <TableCell align="left">
                        {data.suspended_info.restored_time
                          ? new Date(
                              data.suspended_info.restored_time
                            ).toUTCString()
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Stack sx={{ mb: 1 }} spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || exportLoading}
              onClick={() => exportCall({ responseType: 'blob' })}
            >
              <FileDownloadIcon sx={{ mr: '5px' }} /> Export
            </Button>
          </Stack>
          <DataTable
            loading={loading}
            data={data?.occurrence || []}
            columns={columns}
            filterMode={'client'}
            client={true}
            disableRowSelectionOnClick={true}
          />
        </>
      )}
    </Box>
  );
}
