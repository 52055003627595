import React from 'react';

import Box from '@mui/material/Box';

import Title from '../../../common/Title';
import OrganizationForm from '../OrganizationForm';

const OrganizationCreate = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Organization Create" gutterBottom />
      <OrganizationForm mode="create" />
    </Box>
  );
};

export default OrganizationCreate;
