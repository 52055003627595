import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import OrganizationForm from '../OrganizationForm';
import { __getOrganizationDetails } from '../../../../api/organization';

const OrganizationUpdate = () => {
  const [organization, setOrganization] = useState([]);
  const { organization_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getOrganizationDetails(organization_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setOrganization(response.data);
    }
  }, [response]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Organization Update" gutterBottom />
      <OrganizationForm
        mode="update"
        initialValues={organization}
        organizationId={organization.id}
      />
    </Box>
  );
};

export default OrganizationUpdate;
