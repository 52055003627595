import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import useAuth from '../../hooks/useAuth';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import { ATT, JASPER } from '../../constants/value';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorageIcon from '@mui/icons-material/Storage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataArrayIcon from '@mui/icons-material/DataArray';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const SideNav = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  const allItemTop = [
    {
      text: 'Organization',
      to: '/organization',
      icon: <CorporateFareIcon />,
      isSuperUserView: true,
      isAdminView: true,
    },
    {
      text: 'Users',
      to: '/user',
      icon: <ManageAccountsIcon />,
      isSuperUserView: true,
      isAdminView: true,
    },
    {
      text: 'Line Accounts',
      to: '/account',
      icon: <PersonIcon />,
      isSuperUserView: false,
      isAdminView: false,
      orgType: ATT,
    },
    {
      text: 'Line Usage',
      to: '/line_usage',
      icon: <StorageIcon />,
      isSuperUserView: false,
      isAdminView: false,
      orgType: ATT,
    },
    {
      text: 'Service Activation',
      to: '/service-activation',
      icon: <AddCircleIcon />,
      isSuperUserView: false,
      isAdminView: true,
      orgType: ATT,
    },
    {
      text: 'Single User Code',
      to: '/single-user-code',
      icon: <DataArrayIcon />,
      isSuperUserView: false,
      isAdminView: true,
      orgType: ATT,
    },
    {
      text: 'Devices',
      to: '/device',
      icon: <ManageAccountsIcon />,
      isSuperUserView: false,
      isAdminView: true,
      orgType: JASPER,
    },
  ];

  const listItemTop = allItemTop.filter(
    eachItem =>
      eachItem.isSuperUserView === user.is_superuser &&
      (eachItem.isAdminView === user.is_admin || !eachItem.isAdminView) &&
      user?.organization?.type === eachItem.orgType
  );
  const listItemBottom = [
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      hasNoLink: true,
      onclick: () => {
        localStorage.clear();
        nav('/login');
      },
    },
  ];

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {listItemTop.map(list => (
          <ListItem button key={list.text} component={Link} to={list.to}>
            <ListItemIcon>{list.icon}</ListItemIcon>
            <ListItemText primary={list.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {listItemBottom.map(list =>
          !list.hasNoLink ? (
            <ListItem button key={list.text} component={Link} to={list.to}>
              <ListItemIcon>{list.icon}</ListItemIcon>
              <ListItemText primary={list.text} />
            </ListItem>
          ) : (
            <ListItem button key={list.text} onClick={list.onclick}>
              <ListItemIcon>{list.icon}</ListItemIcon>
              <ListItemText primary={list.text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
};

export default SideNav;
