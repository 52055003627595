import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import { __getUserDetails } from '../../../../api/user';
import { USER_DETAIL_LABEL } from '../../../../constants/responseMapping';

const UserDetail = () => {
  const nav = useNavigate();
  const [user, setUser] = useState(null);
  const { user_id } = useParams();
  const [response, call, loading] = useAxios({
    ...__getUserDetails(user_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setUser(response.data);
    }
  }, [response]);

  if (loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!user) {
    return <Title content="User Not found" variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Title variant="h5" content="User Details" gutterBottom />
      <Box>
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => nav('update')}
            variant="contained"
            color="success"
          >
            Update
          </Button>
        </Stack>
        <Box sx={{ m: 2 }}>
          <Divider></Divider>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              {Object.keys(USER_DETAIL_LABEL).map(key => {
                if (key === 'organization') {
                  return (
                    <TableRow
                      key={key}
                      component={Link}
                      to={
                        user.organization?.id
                          ? `/organization/${user.organization?.id}`
                          : '#'
                      }
                    >
                      <TableCell>{USER_DETAIL_LABEL[key]}</TableCell>
                      <TableCell>{user[key]?.name || '-'}</TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow key={key}>
                    <TableCell>{USER_DETAIL_LABEL[key]}</TableCell>
                    <TableCell>{user[key].toString() || '-'}</TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default UserDetail;
