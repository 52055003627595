import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import OrganizationList from './list/OrganizationList';
import { __getOrganizationList } from '../../../api/organization';

const Organization = () => {
  const nav = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [response, call] = useAxios({ ...__getOrganizationList });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setOrganizations(response.data.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => nav('create')}
          variant="contained"
          color="success"
        >
          Create
        </Button>
      </Stack>
      <Title variant="h5" content="Organization List" gutterBottom />
      {response ? (
        <OrganizationList organizations={organizations} />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default Organization;
