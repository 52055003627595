import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import SingleUserCodeForm from '../SingleUserCodeForm';
import { __getSingleUserCode } from '../../../../api/singleUserCode';

const SingleUserCodeUpdate = () => {
  const [singleUserCode, setSingleUserCode] = useState([]);
  const { single_user_code_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getSingleUserCode(single_user_code_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setSingleUserCode(response.data);
    }
  }, [response]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Single User Code Update" gutterBottom />
      <SingleUserCodeForm
        mode="update"
        initialValues={singleUserCode}
        singleUserCodeId={singleUserCode.id}
      />
    </Box>
  );
};

export default SingleUserCodeUpdate;
