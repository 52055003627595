import React from 'react';

import TextInfo from './TextInfo';

import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';

const InputFieldBox = ({
  id,
  type,
  name,
  label,
  value,
  error,
  options,
  disabled,
  onChange,
  isSelect,
  isTouched,
  isCheckbox,
}) => {
  return (
    <FormControl
      error={isTouched && Boolean(error) ? true : false}
      sx={{ width: '100%' }}
    >
      {!isCheckbox ? (
        <>
          <InputLabel htmlFor={id}>{label}</InputLabel>

          {!isSelect ? (
            <OutlinedInput
              type={type || ''}
              id={id}
              name={name}
              label={label}
              value={value}
              onChange={onChange}
            />
          ) : (
            <Select
              id={id}
              name={name}
              label={label}
              value={value}
              onChange={onChange}
              disabled={disabled}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      ) : (
        <FormControlLabel
          id={id}
          name={name}
          label={label}
          value={value}
          control={
            <Checkbox onChange={onChange} checked={value ? true : false} />
          }
        />
      )}
      {isTouched && Boolean(error) ? <TextInfo error text={error} /> : ''}
    </FormControl>
  );
};

export default InputFieldBox;
