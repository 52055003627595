import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import {
  __activateDevice,
  __getDeviceDetail,
  __deactivateDevice,
} from '../../../../api/jasper';
import { JASPER_STATUS } from '../../../../constants/value';
import { JASPER_DEVICE_DETAIL_LABEL } from '../../../../constants/responseMapping';

const DeviceDetail = () => {
  const nav = useNavigate();
  const [apiCall, setApiCall] = useState(null);
  const [details, setDetails] = useState(null);
  const { iccid } = useParams();
  const [response, call, loading] = useAxios({
    ...__getDeviceDetail(iccid),
  });
  const [apiLoading, setApiLoading] = useState(false);

  const [statusResponse, statusCall, statusLoading] = useAxios(apiCall);

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (apiCall) {
      statusCall();
    }
  }, [apiCall]);

  useEffect(() => {
    if (response && response.status) {
      setDetails(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (statusResponse && statusResponse.status) {
      call();
    }
  }, [statusResponse]);

  if (loading || apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!details || !Object.keys(details).length) {
    return (
      <Title content="Data not available" variant="subtitle2" gutterBottom />
    );
  }

  const request = {
    activate: __activateDevice(iccid),
    deactivate: __deactivateDevice(iccid),
  };

  const changeStatus = status => {
    setApiCall(request[status]);
  };

  const getButtonType = status => {
    if (status === JASPER_STATUS.ACTIVATED) {
      return (
        <Button
          variant="contained"
          color="error"
          onClick={() => changeStatus('deactivate')}
        >
          Deactivate
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="success"
          onClick={() => changeStatus('activate')}
        >
          Activate
        </Button>
      );
    }
  };

  return (
    <Box>
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
        {getButtonType(details.status)}

        <Button
          variant="contained"
          color="success"
          onClick={() => nav('change_rate_plan')}
        >
          Change Rate Plan
        </Button>
      </Stack>
      <Box sx={{ m: 2 }}>
        <Divider></Divider>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            {Object.keys(JASPER_DEVICE_DETAIL_LABEL).map(key => (
              <TableRow key={key}>
                <TableCell>{JASPER_DEVICE_DETAIL_LABEL[key]}</TableCell>
                <TableCell>
                  {key.includes('date') && details[key]
                    ? new Date(details[key]).toLocaleString()
                    : details[key] || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeviceDetail;
