import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';

import Pagination from './Pagination';
import Title from '../../common/Title';
import DeviceList from './list/DeviceList';
import useAxios from '../../../hooks/useAxios';
import SearchBoxDevice from './search/SearchBoxDevice';
import SearchViewDevice from './search/SearchViewDevice';
import {
  __exportCsv,
  __getDeviceList,
  __searchDevice,
} from '../../../api/jasper';

import axios from '../../../config/axios';

const Device = () => {
  const [devices, setDevices] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState();
  let [response, call, loading] = useAxios({ ...__getDeviceList });
  const [searchResponse, callSearch] = useAxios({
    ...__searchDevice,
  });

  useEffect(() => {
    call({ params: { page: currentPage } });
  }, [currentPage]);

  useEffect(() => {
    if (response && response.status) {
      const totalReqPages = parseInt(response.data.total / 50) + 1;
      setTotalPage(totalReqPages);
      setLastUpdated(new Date(response.data.last_updated));
      setDevices(response.data.devices);
    }
  }, [response]);

  const handlePageChange = page => {
    clearSearch();
    setCurrentPage(page);
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResult();
  };

  const handleSearch = () => {
    callSearch({ params: { iccid: searchValue } });
  };

  useEffect(() => {
    if (searchResponse && searchResponse.status) {
      setSearchResult(searchResponse.data);
    }
  }, [searchResponse]);

  const downloadExport = () => {
    axios
      .get(__exportCsv.url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, 'jasper_export.csv');
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Device List" gutterBottom />
      {response && (
        <Grid container justifyContent="space-between">
          <Grid item xs={5}>
            <Stack sx={{ mb: '5px' }}>
              <SearchBoxDevice
                searchValue={searchValue}
                clearSearch={clearSearch}
                handleSubmit={handleSearch}
                changeSearchValue={setSearchValue}
              />
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ mb: 3 }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<DescriptionIcon />}
              onClick={downloadExport}
            >
              Export all devices' data
            </Button>
          </Grid>
        </Grid>
      )}
      {searchResult ? (
        <SearchViewDevice deviceData={searchResult} />
      ) : response ? (
        <>
          <Box sx={{ marginTop: 4 }}>Last Update: {lastUpdated && lastUpdated.toLocaleString()}</Box>
          <DeviceList devices={devices} />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
      {!searchResult ? (
        <Box sx={{ marginTop: 4 }}>
          <Pagination
            handlePageChange={handlePageChange}
            totalPage={totalPage}
            disabled={loading}
            currentPage={currentPage}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default Device;
