import Axios from 'axios';
import { useState } from 'react';

import useAuth from './useAuth';
import useAlert from './useAlert';
import axios from '../config/axios';
import useLoading from './useLoading';

let CancelToken = Axios.CancelToken;

export default function useAxios(options) {
  const { resetAuthData } = useAuth();
  const { setAlertMessage } = useAlert();
  const { setIsLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const call = async (callOptions) => {
    try {
      setIsLoading({ isLoading: true });
      setLoading(true);
      const res = await axios({
        ...options,
        ...callOptions,
      });
      const data = typeof res.data === 'object' ? res.data : {};
      setResponse({ data, status: true });
      if (data) {
        setLoading(false);
        setIsLoading({ isLoading: false });
      }
      return { ...data };
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          resetAuthData();
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
        const { data } = error.response;
        setAlertMessage({ type: 'error', message: JSON.stringify(data) });
        setResponse({ data, status: false });
        setLoading(false);
        setIsLoading({ isLoading: false });
      }
      return false;
    }
  };

  return [response, call, loading];
}
