import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import UserList from './list/UserList';
import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import { __getUserList } from '../../../api/user';

const User = () => {
  const nav = useNavigate();
  const [users, setUsers] = useState([]);
  const [response, call] = useAxios({ ...__getUserList });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setUsers(response.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => nav('create')}
          variant="contained"
          color="success"
        >
          Create
        </Button>
      </Stack>
      <Title variant="h5" content="User List" gutterBottom />
      {response ? (
        <UserList users={users} />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default User;
