const initialAlert = {
  message: '',
  type: '',
};

const setAlertMessage = (state, payload) => {
  return {
    ...state,
    message: payload.message,
    type: payload.type,
  };
};

const resetAlertMessage = () => {
  return {
    message: '',
    type: '',
  };
};

const alertReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALERT_MESSAGE':
      return setAlertMessage(state, action.payload);
    case 'RESET_ALERT_MESSAGE':
      return resetAlertMessage();
    default:
      throw new Error();
  }
};

export { initialAlert, alertReducer };
