import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import { __getServiceActivationList } from '../../../api/account';
import { SERVICE_ACTIVATION_LIST_LABEL } from '../../../constants/responseMapping';

const ServiceActivationMain = () => {
  const nav = useNavigate();
  const [activationData, setActivationData] = useState([]);
  const [response, call] = useAxios({ ...__getServiceActivationList });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setActivationData(response.data.data);
    }
  }, [response]);

  const activationListTable = activationList => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            {Object.keys(SERVICE_ACTIVATION_LIST_LABEL).map(key => (
              <TableCell key={key}>
                {SERVICE_ACTIVATION_LIST_LABEL[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {activationList.map(row => (
            <TableRow
              component={Link}
              to={`${row.activation_id}`}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.keys(SERVICE_ACTIVATION_LIST_LABEL).map(key => (
                <TableCell key={key}>
                  {key === 'timestamp'
                    ? new Date(row[key]).toLocaleDateString()
                    : row[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Service Activation" gutterBottom />
      <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => nav('activate')}
        >
          Activate new service
        </Button>
      </Stack>
      {response ? (
        activationListTable(activationData)
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ServiceActivationMain;
