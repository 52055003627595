import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import ServiceActivationDetail from './ServiceActivationDetail';
import { __getServiceActivationStatus } from '../../../api/account';

const ServiceActivationView = () => {
  const [serviceActivationData, setServiceActivationData] = useState([]);
  const { activation_id } = useParams();
  const [response, call] = useAxios({
    ...__getServiceActivationStatus(activation_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setServiceActivationData(response.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Service Activation Detail" gutterBottom />
      {response ? (
        <ServiceActivationDetail activationData={serviceActivationData} />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ServiceActivationView;
