import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';

import {
  __createSingleUserCode,
  __updateSingleUserCode,
} from '../../../api/singleUserCode';
import useAxios from '../../../hooks/useAxios';

const SingleUserCodeForm = ({ initialValues, mode, singleUserCodeId }) => {
  let initialData = {
    name: '',
    code: '',
    data_limit: '',
  };
  const isUpdate = mode === 'update' && singleUserCodeId;
  const nav = useNavigate();
  const [formData, setFormData] = useState(null);
  const apiMethod = isUpdate
    ? __updateSingleUserCode(singleUserCodeId)
    : __createSingleUserCode;

  const createValidationSchema = yup.object({
    name: yup.string('Name').required('Name is required'),
    code: yup.string('Code').required('Code is required'),
    data_limit: yup.number('Data').required('Data is required'),
  });

  const updateValidationSchema = yup.object({
    name: yup.string('Name'),
    code: yup.string('Code'),
    data_limit: yup.number('Data'),
  });

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? { ...initialValues }
        : { ...initialData },
    validationSchema: isUpdate
      ? updateValidationSchema
      : createValidationSchema,
    onSubmit: values => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (values[key]) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  const [response, call, loading] = useAxios({
    ...apiMethod,
    data: formData,
  });

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      nav('/single-user-code');
    }
  }, [response]);

  useEffect(() => {
    if (formData) {
      call();
    }
  }, [formData]);

  return (
    <>
      <Grid sx={{ flexGrow: 1 }} container spacing={2} rowSpacing={2}>
        <Grid item xs={8} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.name && Boolean(formik.errors.name) ? true : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="name">Name</InputLabel>
            <OutlinedInput
              name="name"
              label="Name"
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && Boolean(formik.errors.name) ? (
              <FormHelperText id="name-error-text">
                {formik.errors.name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.code && Boolean(formik.errors.code) ? true : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="code">Code</InputLabel>
            <OutlinedInput
              type="code"
              name="code"
              label="Code"
              id="code"
              value={formik.values.code}
              onChange={formik.handleChange}
            />
            {formik.touched.code && Boolean(formik.errors.code) ? (
              <FormHelperText id="code-error-text">
                {formik.errors.code}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.data_limit && Boolean(formik.errors.data_limit)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="data_limit">Data Limit</InputLabel>
            <OutlinedInput
              type="data_limit"
              name="data_limit"
              label="Data Limit"
              id="data_limit"
              startAdornment={<InputAdornment position="start">GB</InputAdornment>}
              value={formik.values.data_limit}
              onChange={formik.handleChange}
            />
            {formik.touched.data_limit && Boolean(formik.errors.data_limit) ? (
              <FormHelperText id="data_limit-error-text">
                {formik.errors.data_limit}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>

        <Grid justify="flex-end" alignItems="center" container item>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              onClick={() =>
                !loading && !formik.isSubmitting && formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              {isUpdate ? 'Update' : 'Create'}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              size="large"
              onClick={() => nav('/single-user-code')}
              loading={loading}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SingleUserCodeForm;
