import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { ORGANIZATION_MAPPING } from '../../../../constants/responseMapping';

const OrganizationList = ({ organizations }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (organizations && organizations.length) {
      setRows(organizations.map(each => each));
    }
  }, [organizations]);

  if (!rows.length) {
    return (
      <Box
        sx={{ width: '100%', boxShadow: 1, borderRadius: 1, p: 4 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom component="div">
          Organizations not available
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Id.</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              component={Link}
              to={`${row.id}`}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell scope="row">
                <Button size="small">{row.id}</Button>
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">
                {ORGANIZATION_MAPPING[row.type]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrganizationList;
