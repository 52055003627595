import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { gridNumberComparator } from '@mui/x-data-grid';
import { Box, Button, Grid, Stack } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Title from '../../../common/Title';
import DataTable from '../../../common/Datatable';

import useAxios from '../../../../hooks/useAxios';
import {
  __getAllLineUsage,
  __lineUsageSummaryExport,
} from '../../../../api/account';
import { getCycleRanges } from '../../../../utils/date';
import CustomSelect from '../../../common/CustomSelect';

export default function UsageSummary() {
  const nav = useNavigate();
  const handleRowClick = e => {
    nav(`${e.row.subscriber_number}`);
  };

  const columns = [
    {
      field: 'subscriber_number',
      headerName: 'Subscriber Number',
      flex: 1,
      sortable: true,
    },
    {
      field: 'data_usage',
      headerName: 'Data Usage [GB]',
      flex: 1,
      sortable: true,
      valueGetter: params =>
        (params.row.data_usage / 1024 / 1024).toFixed(2) || 0,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      flex: 2,
      valueGetter: params =>
        params.row.last_updated &&
        new Date(params.row.last_updated).toUTCString(),
    },
    {
      field: 'cycle_start',
      headerName: 'Cycle Start Date',
      flex: 2,
      valueGetter: params => {
        const date = params.row.cycle_start && new Date(params.row.cycle_start);
        if (!date) return '';
        return `${date.getUTCFullYear()}-${
          date.getUTCMonth() + 1
        }-${date.getUTCDate()}`;
      },
    },
    {
      field: 'cycle_end',
      headerName: 'Cycle End Date',
      flex: 2,
      valueGetter: params => {
        const date = params.row.cycle_end && new Date(params.row.cycle_end);
        if (!date) return '';
        return `${date.getUTCFullYear()}-${
          date.getUTCMonth() + 1
        }-${date.getUTCDate()}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
  ];

  const dateRangeOptions = getCycleRanges();

  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState(dateRangeOptions.at(-1));
  const [response, call, loading] = useAxios({ ...__getAllLineUsage });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__lineUsageSummaryExport,
  });

  useEffect(() => {
    call({ params: { start_date: dateRange.start, end_date: dateRange.end } });
  }, [dateRange]);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `line_usage_summary.csv`);
    }
  }, [exportResponse]);

  if (loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content={`Data Usage Summary`} gutterBottom />
      {loading ? (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      ) : (
        <>
          <Stack sx={{ mb: 1 }} spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading || exportLoading}
              onClick={() =>
                exportCall({
                  responseType: 'blob',
                  params: {
                    start_date: dateRange.start,
                    end_date: dateRange.end,
                  },
                })
              }
            >
              <FileDownloadIcon sx={{ mr: '5px' }} /> Export
            </Button>
            <Grid item xs={5} minWidth={350}>
              <CustomSelect
                isClearable={false}
                isSearchable={false}
                closeMenuOnSelect={true}
                options={dateRangeOptions}
                defaultValue={dateRange}
                handleChange={val => setDateRange(val)}
                getOptionLabel={range =>
                  `${range.start.getUTCFullYear()}-${
                    range.start.getUTCMonth() + 1
                  }-${range.start.getUTCDate()} - ${range.end.getUTCFullYear()}-${
                    range.end.getUTCMonth() + 1
                  }-${range.end.getUTCDate()}`
                }
              />
            </Grid>
          </Stack>
          <DataTable
            loading={loading}
            data={data}
            columns={columns}
            handleRowClick={handleRowClick}
            filterMode={'client'}
            client={true}
            getRowId={row => row.subscriber_number}
            disableRowSelectionOnClick={true}
          />
        </>
      )}
    </Box>
  );
}
