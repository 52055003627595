import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { ATT } from '../../../constants/value';

const Home = () => {
  const { user } = useAuth();
  if (user.is_superuser) {
    return <Navigate to="/organization" />;
  }
  return user?.organization?.type === ATT ? (
    <Navigate to="/account" />
  ) : (
    <Navigate to="/device" />
  );
};

export default Home;
