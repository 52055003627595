import React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import useLoading from '../../hooks/useLoading';

const LoadingBox = () => {
  const { isLoading } = useLoading();
  return isLoading ? (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999999,
      }}
    >
      <LinearProgress color="primary" />
    </Box>
  ) : (
    ''
  );
};

export default LoadingBox;
