import { set, add, sub } from 'date-fns';

const getFormatDate = date => {
  return `${new Date(date).getFullYear()}-${
    new Date(date).getMonth() + 1
  }-${new Date(date).getDate()}`;
};

const getCycleRanges = () => {
  const currentDate = new Date();
  const utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;

  // pacific time to get the current month
  const pacificTime = new Date(utc - 3600000 * 7);
  const currentMonth = pacificTime.getMonth();
  const startDate =
    pacificTime.getDate() >= 11
      ? set(new Date(), { date: 11 })
      : currentMonth - 1 >= 0
      ? set(new Date(), { month: currentMonth - 1, date: 11 })
      : set(new Date(), {
          year: pacificTime.getFullYear() - 1,
          month: 12,
          date: 11,
        });
  startDate.setUTCHours(7, 0, 0, 0);

  const endDate = sub(add(new Date(startDate), { months: 1 }), { seconds: 1 });

  const dateArrays = Array.from({ length: 6 }, (x, i) => {
    const start = sub(new Date(startDate), { months: i });
    const end = sub(new Date(endDate), { months: i });

    return { start, end, value: i };
  });
  dateArrays.reverse();

  return dateArrays;
};

export { getFormatDate, getCycleRanges };
