const __getOrganizationList = {
  method: 'GET',
  url: 'organization/',
};

const __getOrganizationUsers = organization_id => ({
  method: 'GET',
  url: `user/?organization_id=${organization_id}`,
});

const __getOrganizationDetails = organization_id => ({
  method: 'GET',
  url: `organization/${organization_id}`,
});

const __createOrganization = {
  method: 'POST',
  url: 'organization/',
};

const __updateOrganization = organization_id => ({
  method: 'PUT',
  url: `organization/${organization_id}`,
});

export {
  __createOrganization,
  __updateOrganization,
  __getOrganizationList,
  __getOrganizationUsers,
  __getOrganizationDetails,
};
