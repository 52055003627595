import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../common/Title';
import {
  LINE_DETAIL_LABEL,
  SERVICE_ACTIVATION_DETAIL,
} from '../../../constants/responseMapping';

const ServiceActivationDetail = ({ activationData }) => {
  if (!activationData || !Object.keys(activationData).length) {
    return (
      <Title content="Data not available" variant="subtitle2" gutterBottom />
    );
  }

  return (
    <Box>
      <Box sx={{ m: 2 }}>
        <Divider></Divider>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            {Object.keys(SERVICE_ACTIVATION_DETAIL).map(key => (
              <TableRow key={key}>
                <TableCell>{SERVICE_ACTIVATION_DETAIL[key]}</TableCell>
                <TableCell>{activationData[key] || '-'}</TableCell>
              </TableRow>
            ))}
            {Object.keys(LINE_DETAIL_LABEL).map(key => {
              const mainData =
                key === 'single_user_code' ? (
                  <>
                    <TableCell>{LINE_DETAIL_LABEL[key]}</TableCell>
                    <TableCell>
                      {`${activationData.line_details[key].name} - ${activationData.line_details[key].code}`}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{LINE_DETAIL_LABEL[key]}</TableCell>
                    <TableCell>
                      {activationData.line_details[key] || '-'}
                    </TableCell>
                  </>
                );
              if (key === 'subscriber_number') {
                return (
                  <TableRow
                    component={Link}
                    to={`/account/${activationData.line_details[key]}`}
                    key={key}
                  >
                    {mainData}
                  </TableRow>
                );
              }

              return <TableRow key={key}>{mainData}</TableRow>;
            })}
            <TableRow key={'error'}>
              <TableCell>Error</TableCell>
              <TableCell>{activationData.error || '-'}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ServiceActivationDetail;
