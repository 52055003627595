import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBoxDevice({
  handleSubmit,
  searchValue,
  changeSearchValue,
  clearSearch,
}) {
  const [fieldValue, setFieldValue] = useState(searchValue);
  const [formDisabled, setFormDisabled] = useState(true);

  useEffect(() => {
    setFieldValue(searchValue);
  }, []);

  
  const handleSearchSubmit = () => {
    setFormDisabled(true);
    setFieldValue(searchValue);
    handleSubmit();
  };

  useEffect(() => {
    setFormDisabled(!searchValue || fieldValue === searchValue);
  }, [searchValue]);

  const handleChange = e => {
    changeSearchValue(e.target.value);
  };

  const handleEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchValue && !formDisabled && handleSearchSubmit();
    }
  };

  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        onChange={handleChange}
        onKeyDown={handleEnter}
        placeholder="Search using ICCID"
        inputProps={{ 'aria-label': 'search iccid' }}
        value={searchValue}
      />
      {searchValue && (
        <IconButton
          disabled={!searchValue}
          onClick={clearSearch}
          sx={{ p: '10px' }}
          aria-label="search"
        >
          <CancelIcon />
        </IconButton>
      )}
      <IconButton
        disabled={formDisabled}
        onClick={handleSearchSubmit}
        sx={{ p: '10px' }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
