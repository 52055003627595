import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

import useAxios from '../../../hooks/useAxios';
import InputFieldBox from '../../common/InputFieldBox';
import { __createUser, __updateUser } from '../../../api/user';
import { __getOrganizationList } from '../../../api/organization';
import { Checkbox, FormControlLabel } from '@mui/material';

const validUpdateKeys = [
  'first_name',
  'last_name',
  'organization_id',
  'password',
  'is_admin',
];
const validCreateKeys = [...validUpdateKeys, 'email'];

const UserForm = ({ initialValues, mode, userId }) => {
  const isUpdate = mode === 'update' && userId;
  const filteredInitialValues =
    initialValues &&
    Object.keys(initialValues).length &&
    Object.keys(initialValues).reduce((obj, key) => {
      if (isUpdate && validUpdateKeys.includes(key))
        obj[key] = initialValues[key];
      if (!isUpdate && validCreateKeys.includes(key))
        obj[key] = initialValues[key];
      return obj;
    }, {});
  let initialData = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    organization_id: '',
    is_admin: false,
  };
  const nav = useNavigate();
  const apiMethod = isUpdate ? __updateUser(userId) : __createUser;
  const [formData, setFormData] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [organizationResponseCode, organizationListCall, apiLoading] = useAxios(
    {
      ...__getOrganizationList,
    }
  );

  const createValidationSchema = yup.object({
    first_name: yup.string('First Name').required('First Name is required'),
    last_name: yup.string('Last Name').required('Last Name is required'),
    email: yup
      .string('Email')
      .required('Email is required')
      .email('Valid email'),
    password: yup
      .string('Password')
      .required('Password is required')
      .min(8, 'Minimum 8 characters'),
    organization_id: yup
      .string('Organization')
      .required('Organization is required'),
    is_admin: yup.bool('Is Admin').required(),
  });

  const updateValidationSchema = yup.object({
    first_name: yup.string('First Name'),
    last_name: yup.string('Last Name'),
    email: yup.string('Email').email(),
    password: yup.string('Password').min(8, 'Minimum 8 characters'),
    organization_id: yup.string('Organization'),
    is_admin: yup.bool('Is Admin'),
  });

  const formik = useFormik({
    initialValues:
      filteredInitialValues && Object.keys(filteredInitialValues).length
        ? { ...filteredInitialValues }
        : { ...initialData },
    validationSchema: isUpdate
      ? updateValidationSchema
      : createValidationSchema,
    onSubmit: values => {
      console.log(values);
      const requestData = Object.keys(values).reduce((obj, key) => {
        console.log({ key, val: values });
        if (key === 'password' && !values[key] && !values[key].length) {
          return obj;
        }
        if (values[key] !== undefined || values[key] !== null) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  const [response, call, loading] = useAxios({
    ...apiMethod,
    data: formData,
  });

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      nav('/user');
    }
  }, [response]);

  useEffect(() => {
    if (formData) {
      call();
    }
  }, [formData]);

  useEffect(() => {
    organizationListCall();
  }, []);

  useEffect(() => {
    if (organizationResponseCode && organizationResponseCode.status) {
      setOrganizations(organizationResponseCode.data.data);
    }
  }, [organizationResponseCode]);

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="first_name">First Name</InputLabel>
            <OutlinedInput
              first_name="first_name"
              label="First Name"
              id="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
            />
            {formik.touched.first_name && Boolean(formik.errors.first_name) ? (
              <FormHelperText id="first_name-error-text">
                {formik.errors.first_name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.last_name && Boolean(formik.errors.last_name)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="last_name">Last Name</InputLabel>
            <OutlinedInput
              type="last_name"
              name="last_name"
              label="Last Name"
              id="last_name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
            {formik.touched.last_name && Boolean(formik.errors.last_name) ? (
              <FormHelperText id="last_name-error-text">
                {formik.errors.last_name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.email && Boolean(formik.errors.email)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <OutlinedInput
              email="email"
              label="Email"
              id="email"
              disabled={isUpdate}
              value={isUpdate ? initialValues.email : formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && Boolean(formik.errors.email) ? (
              <FormHelperText id="email-error-text">
                {formik.errors.email}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.password && Boolean(formik.errors.password)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              type="password"
              name="password"
              label="Password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <FormHelperText id="password-error-text">
                {formik.errors.password}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.organization_id &&
              Boolean(formik.errors.organization_id)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputFieldBox
              isSelect
              options={organizations.map(val => ({
                value: val.id,
                label: val.name,
              }))}
              name="organization_id"
              label="Organization"
              id="organization_id"
              value={formik.values.organization_id}
              onChange={formik.handleChange}
            />
            {formik.touched.organization_id &&
            Boolean(formik.errors.organization_id) ? (
              <FormHelperText id="organization_id-error-text">
                {formik.errors.organization_id}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          {console.log({ formik })}
          <FormControlLabel
            label="Is Admin"
            control={
              <Checkbox
                name="is_admin"
                label="is_admin"
                id="is_admin"
                checked={formik.values.is_admin}
                onChange={e =>
                  formik.setFieldValue('is_admin', e.target.checked)
                }
              />
            }
          />
        </Grid>
        <Grid justify="flex-end" alignItems="center" container item>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              onClick={() =>
                !loading && !formik.isSubmitting && formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              {isUpdate ? 'Update' : 'Create'}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              size="large"
              onClick={() => nav('/user')}
              loading={loading}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
