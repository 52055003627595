const __getAccountList = {
  method: 'GET',
  url: 'att/account_list',
};

const __getAccountListExport = {
  method: 'GET',
  url: 'att/account_list/export',
};

const __getSubscriberActivityHistory = subscriber_number => ({
  method: 'GET',
  url: `att/line_details/${subscriber_number}/activity_history`,
});

const __getSubscriberDetails = subscriber_number => ({
  method: 'GET',
  url: `att/line_details/${subscriber_number}`,
});

const __suspendSubscriber = subscriber_number => ({
  method: 'POST',
  url: `att/suspend_line/${subscriber_number}`,
});

const __restoreSubscriber = subscriber_number => ({
  method: 'POST',
  url: `att/restore_suspended_line/${subscriber_number}`,
});

const __cancelSubscriber = subscriber_number => ({
  method: 'POST',
  url: `att/cancel_line/${subscriber_number}`,
});

const __resumeSubscriber = subscriber_number => ({
  method: 'POST',
  url: `att/resume_cancelled_line/${subscriber_number}`,
});

const __searchLine = {
  method: 'GET',
  url: 'att/search_line_details',
};

const __getServiceActivationStatus = activationId => ({
  method: 'GET',
  url: `att/service_activation/${activationId}`,
});

const __getServiceActivationList = {
  method: 'GET',
  url: 'att/service_activation',
};

const __activateNewService = {
  method: 'POST',
  url: 'att/service_activation/activate',
};

const __changeSubscriberSim = subscriber_number => ({
  method: 'POST',
  url: `att/change_sim/${subscriber_number}`,
});

const __changeSubscriberImei = subscriber_number => ({
  method: 'POST',
  url: `att/change_imei/${subscriber_number}`,
});

const __getAllLineUsage = {
  method: 'GET',
  url: `att/line_usage`,
};

const __getSingleLineUsageOccurrence = subscriber_number => ({
  method: 'GET',
  url: `att/line_usage/${subscriber_number}`,
});

const __lineUsageSummaryExport = {
  method: 'GET',
  url: `att/export/usage_summary`,
};

const __singleLineUsageExport = subscriber_number => ({
  method: 'GET',
  url: `att/export/${subscriber_number}`,
});

export {
  __searchLine,
  __getAccountList,
  __getAllLineUsage,
  __cancelSubscriber,
  __resumeSubscriber,
  __restoreSubscriber,
  __suspendSubscriber,
  __activateNewService,
  __changeSubscriberSim,
  __getAccountListExport,
  __getSubscriberDetails,
  __changeSubscriberImei,
  __singleLineUsageExport,
  __lineUsageSummaryExport,
  __getServiceActivationList,
  __getServiceActivationStatus,
  __getSingleLineUsageOccurrence,
  __getSubscriberActivityHistory,
};
