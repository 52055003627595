import { useReducer } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from '../routes/PrivateRoutes';
import { initialAuth, authReducer } from '../reducer/auth';
import { initialAlert, alertReducer } from '../reducer/alert';
import { initialLoading, loadingReducer } from '../reducer/loading';
import { AuthContext, AlertContext, LoadingContext } from '../context/index';

import Login from './auth/Login';
import Home from './views/home/Home';
import User from './views/user/User';
import { ATT } from '../constants/value';
import AlertBox from './common/AlertBox';
import Device from './views/device/Device';
import LoadingBox from './common/LoadingBox';
import Account from './views/account/Account';
import UserCreate from './views/user/create/UserCreate';
import UserUpdate from './views/user/update/UserUpdate';
import UserDetail from './views/user/details/UserDetails';
import DeviceDetail from './views/device/show/DeviceDetail';
import Organization from './views/organization/Organization';
import SingleUserCode from './views/single-user-code/SingleUserCode';
import SubscriberChangeSim from './views/account/changeSim/ChangeSim';
import SubscriberDetail from './views/account/show/SubscriberDetails';
import SubscriberChangeImei from './views/account/changeImei/ChangeImei';
import ChangeRatePlan from './views/device/changeRatePlan/ChangeRatePlan';
import OrganizationUpdate from './views/organization/update/OrganizationUpdate';
import OrganizationCreate from './views/organization/create/OrganizationCreate';
import OrganizationDetail from './views/organization/details/OrganizationDetail';
import ServiceActivationView from './views/service-activation/ServiceActivationView';
import ServiceActivationMain from './views/service-activation/ServiceActivationMain';
import SingleUserCodeCreate from './views/single-user-code/create/SingeUserCodeCreate';
import SingleUserCodeUpdate from './views/single-user-code/update/SingleUserCodeUpdate';
import ServiceActivationActivate from './views/service-activation/ServiceActivationActivate';

import UsageSummary from './views/account/usage/UsageSummary';
import LineUsageList from './views/account/usage/LineUsageList';

function App() {
  const [auth, authDispatch] = useReducer(authReducer, initialAuth);
  const [alert, alertDispatch] = useReducer(alertReducer, initialAlert);
  const [isLoading, loadingDispatch] = useReducer(
    loadingReducer,
    initialLoading
  );
  return (
    <BrowserRouter>
      <AuthContext.Provider value={[auth, authDispatch]}>
        <AlertContext.Provider value={[alert, alertDispatch]}>
          <LoadingContext.Provider value={[isLoading, loadingDispatch]}>
            <AlertBox />
            <LoadingBox />
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Home />} />
                {auth.user.is_superuser ? (
                  <>
                    <Route path="/organization">
                      <Route index element={<Organization />} />
                      <Route
                        path=":organization_id"
                        element={<OrganizationDetail />}
                      />
                      <Route
                        path=":organization_id/update"
                        element={<OrganizationUpdate />}
                      />
                      <Route path="create" element={<OrganizationCreate />} />
                    </Route>
                    <Route path="/user">
                      <Route index element={<User />} />
                      <Route path=":user_id" element={<UserDetail />} />
                      <Route path=":user_id/update" element={<UserUpdate />} />
                      <Route path="create" element={<UserCreate />} />
                    </Route>
                    <Route path="/single-user-code">
                      <Route index element={<SingleUserCode />} />
                      <Route
                        path=":single_user_code_id/update"
                        element={<SingleUserCodeUpdate />}
                      />
                      <Route path="create" element={<SingleUserCodeCreate />} />
                    </Route>
                  </>
                ) : auth.user?.organization?.type === ATT ? (
                  <>
                    <Route path="/account">
                      <Route index element={<Account />} />
                      <Route
                        path=":subscriber_number"
                        element={<SubscriberDetail />}
                      />
                      <Route
                        path=":subscriber_number/change_sim"
                        element={<SubscriberChangeSim />}
                      />
                      <Route
                        path=":subscriber_number/change_imei"
                        element={<SubscriberChangeImei />}
                      />
                    </Route>
                    <Route path="/line_usage">
                      <Route index element={<UsageSummary />} />
                      <Route
                        path=":subscriber_number"
                        element={<LineUsageList />}
                      />
                    </Route>
                    {auth.user?.is_admin && (
                      <>
                        <Route path="/service-activation">
                          <Route index element={<ServiceActivationMain />} />
                          <Route
                            path="activate"
                            element={<ServiceActivationActivate />}
                          />
                          <Route
                            path=":activation_id"
                            element={<ServiceActivationView />}
                          />
                        </Route>
                        <Route path="/single-user-code">
                          <Route index element={<SingleUserCode />} />
                          <Route
                            path=":single_user_code_id/update"
                            element={<SingleUserCodeUpdate />}
                          />
                          <Route
                            path="create"
                            element={<SingleUserCodeCreate />}
                          />
                        </Route>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Route path="/device">
                      <Route index element={<Device />} />
                      <Route path=":iccid" element={<DeviceDetail />} />
                      <Route
                        path=":iccid/change_rate_plan"
                        element={<ChangeRatePlan />}
                      />
                    </Route>
                  </>
                )}

                <Route path="*" element={<h1>404 Error!!!</h1>} />
              </Route>
            </Routes>
          </LoadingContext.Provider>
        </AlertContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
