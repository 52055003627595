import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import SideNav from './SideNav';
import useAuth from '../../hooks/useAuth';
import useAxios from '../../hooks/useAxios';
import { __getAuthData } from '../../api/auth';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

const drawerWidth = 240;

function Dashboard(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { setUserData } = useAuth();
  const [appConfig, setAppConfig] = useState(false);

  const [response, call] = useAxios({ ...__getAuthData });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      const user = response.data;
      setUserData(user);
      setAppConfig(true);
    }
  }, [response]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {appConfig ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                ATT
              </Typography>
            </>
          ) : (
            <Skeleton variant="rectangular" width="100%">
              <Typography variant="h6" noWrap component="div">
                .
              </Typography>
            </Skeleton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {appConfig ? (
            <SideNav />
          ) : (
            <Skeleton variant="rectangular" height="100%"></Skeleton>
          )}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {appConfig ? (
            <SideNav />
          ) : (
            <Skeleton variant="rectangular" height="100%"></Skeleton>
          )}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: 'calc(100vh - 40px)',
        }}
      >
        <Toolbar />
        {appConfig ? (
          <Outlet />
        ) : (
          <Skeleton
            variant="rectangular"
            height="calc(100vh - 120px)"
          ></Skeleton>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard;
