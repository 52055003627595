import React from 'react';

import Box from '@mui/material/Box';

import Title from '../../../common/Title';
import SingleUserCodeForm from '../SingleUserCodeForm';

const SingleUserCodeCreate = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Singe User Code Create" gutterBottom />
      <SingleUserCodeForm mode="create" />
    </Box>
  );
};

export default SingleUserCodeCreate;
