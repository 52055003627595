const __getDeviceList = {
  method: 'GET',
  url: 'jasper/device',
};

const __searchDevice = {
  method: 'GET',
  url: 'jasper/search_device',
};

const __getDeviceDetail = iccid => ({
  method: 'GET',
  url: `jasper/device/${iccid}`,
});

const __activateDevice = iccid => ({
  method: 'POST',
  url: `jasper/device/${iccid}/activate`,
});

const __deactivateDevice = iccid => ({
  method: 'POST',
  url: `jasper/device/${iccid}/deactivate`,
});

const __updateRatePlan = iccid => ({
  method: 'POST',
  url: `jasper/device/${iccid}/rate_plan_update`,
});

const __getRatePlan = {
  method: 'GET',
  url: 'jasper/rate_plan',
};

const __exportCsv = {
  method: 'GET',
  url: 'jasper/export-devices',
};

export {
  __exportCsv,
  __getRatePlan,
  __searchDevice,
  __getDeviceList,
  __updateRatePlan,
  __activateDevice,
  __getDeviceDetail,
  __deactivateDevice,
};
