import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

import {
  __createOrganization,
  __updateOrganization,
} from '../../../api/organization';
import useAxios from '../../../hooks/useAxios';
import { ATT } from '../../../constants/value';
import InputFieldBox from '../../common/InputFieldBox';

const ORGANIZATION_ENUM = [
  { value: 'att', label: 'ATT' },
  { value: 'jasper', label: 'Jasper' },
];

const app_cred_labels = {
  att: { app_id: 'App ID', app_secret: 'App Secret' },
  jasper: { app_id: 'Username', app_secret: 'API Key' },
};

const OrganizationForm = ({ initialValues, mode, organizationId }) => {
  let initialData = { app_id: '', app_secret: '', type: ATT };
  const isUpdate = mode === 'update' && organizationId;
  const nav = useNavigate();
  const apiMethod = isUpdate
    ? __updateOrganization(organizationId)
    : __createOrganization;
  const [formData, setFormData] = useState(null);

  const updateValidationSchema = yup.object({
    name: yup.string('Name of the Organization').required('Name is required'),
    app_id: yup.string('Enter att app id').min(8, 'Minimum 8 Characters'),
    app_secret: yup
      .string('Enter att app secret')
      .min(8, 'Minimum 8 Characters'),
  });

  const createValidationSchema = yup.object({
    name: yup.string('Name of the Organization').required('Name is required'),
    type: yup
      .string('Type of portal user can access')
      .required('Type is required')
      .oneOf(ORGANIZATION_ENUM.map(val => val.value)),
    app_id: yup
      .string('Enter att app id')
      .required('App Id is required')
      .min(8),
    app_secret: yup
      .string('Enter att app secret')
      .required('App Secret is required')
      .min(8),
  });

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name || '',
      type: initialValues?.type || ATT,
      ...initialData,
    },
    validationSchema: isUpdate
      ? updateValidationSchema
      : createValidationSchema,
    onSubmit: values => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (values[key]) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
  });

  const [response, call, loading] = useAxios({
    ...apiMethod,
    data: formData,
  });

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      nav('/organization');
    }
  }, [response]);

  useEffect(() => {
    if (formData) {
      call();
    }
  }, [formData]);

  return (
    <Grid>
      <Grid item xs={12} sx={{ mb: 3, mt: 2 }}>
        <FormControl
          error={
            formik.touched.name && Boolean(formik.errors.name) ? true : false
          }
          sx={{ width: '100%' }}
        >
          <InputLabel htmlFor="name">Name</InputLabel>
          <OutlinedInput
            name="name"
            label="Name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.touched.name && Boolean(formik.errors.name) ? (
            <FormHelperText id="name-error-text">
              {formik.errors.name}
            </FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6} sx={{ mb: 3, mt: 2 }}>
        <FormControl
          error={
            formik.touched.type && Boolean(formik.errors.type) ? true : false
          }
          sx={{ width: '100%' }}
        >
          <InputFieldBox
            isSelect
            options={ORGANIZATION_ENUM}
            name="type"
            label="Type"
            id="type"
            disabled={isUpdate}
            value={isUpdate ? initialValues.type : formik.values.type}
            onChange={isUpdate ? null : formik.handleChange}
          />
          {formik.touched.type && Boolean(formik.errors.type) ? (
            <FormHelperText id="type-error-text">
              {formik.errors.type}
            </FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <FormControl
          error={
            formik.touched.app_id && Boolean(formik.errors.app_id)
              ? true
              : false
          }
          sx={{ width: '100%' }}
        >
          <InputLabel htmlFor="app_id">
            {app_cred_labels[formik.values.type]?.app_id ||
              app_cred_labels.att.app_id}
          </InputLabel>
          <OutlinedInput
            type="app_id"
            name="app_id"
            label={
              app_cred_labels[formik.values.type]?.app_id ||
              app_cred_labels.att.app_id
            }
            id="app_id"
            value={formik.values.app_id}
            onChange={formik.handleChange}
          />
          {formik.touched.app_id && Boolean(formik.errors.app_id) ? (
            <FormHelperText id="app_id-error-text">
              {formik.errors.app_id}
            </FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <FormControl
          error={
            formik.touched.app_secret && Boolean(formik.errors.app_secret)
              ? true
              : false
          }
          sx={{ width: '100%' }}
        >
          <InputLabel htmlFor="app_secret">
            {app_cred_labels[formik.values.type]?.app_secret ||
              app_cred_labels.att.app_secret}
          </InputLabel>
          <OutlinedInput
            type="app_secret"
            name="app_secret"
            label={
              app_cred_labels[formik.values.type]?.app_secret ||
              app_cred_labels.att.app_secret
            }
            id="app_secret"
            value={formik.values.app_secret}
            onChange={formik.handleChange}
          />
          {formik.touched.app_secret && Boolean(formik.errors.app_secret) ? (
            <FormHelperText id="app_secret-error-text">
              {formik.errors.app_secret}
            </FormHelperText>
          ) : (
            ''
          )}
        </FormControl>
      </Grid>
      <Grid justify="flex-end" alignItems="center" container>
        <Grid item>
          <LoadingButton
            style={{ marginRight: '5px' }}
            onClick={() =>
              !loading && !formik.isSubmitting && formik.submitForm()
            }
            size="large"
            loading={loading}
            variant="contained"
          >
            {isUpdate ? 'Update' : 'Create'}
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            style={{ marginRight: '5px' }}
            size="large"
            onClick={() => nav('/organization')}
            loading={loading}
            variant="contained"
          >
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationForm;
