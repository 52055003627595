import { useContext } from 'react';

import { AuthContext } from '../context/index';

const useAuth = () => {
  const [auth, dispatchAuth] = useContext(AuthContext);
  const { user } = auth;

  const setUserData = (data) => {
    dispatchAuth({
      type: 'SET_USER_DATA',
      payload: data,
    });
  };

  const setAccessTokenData = (data) => {
    dispatchAuth({
      type: 'SET_ACCESS_TOKEN_DATA',
      payload: data,
    });
  };

  const setRefreshTokenData = (data) => {
    dispatchAuth({
      type: 'SET_REFRESH_TOKEN_DATA',
      payload: data,
    });
  };

  const resetAuthData = () => {
    dispatchAuth({
      type: 'RESET_AUTH_DATA',
    });
  };

  return {
    auth,
    user,
    setUserData,
    resetAuthData,
    setAccessTokenData,
    setRefreshTokenData,
  };
};

export default useAuth;
