const __getSingleUserCodes = {
  method: 'GET',
  url: 'single_user_code/',
};

const __getSingleUserCode = id => ({
  method: 'GET',
  url: `single_user_code/${id}`,
});

const __createSingleUserCode = {
  method: 'POST',
  url: 'single_user_code/',
};

const __updateSingleUserCode = id => ({
  method: 'PUT',
  url: `single_user_code/${id}`,
});

const __deleteSingleUserCode = id => ({
  method: 'DELETE',
  url: `single_user_code/${id}`,
});

export {
  __getSingleUserCode,
  __getSingleUserCodes,
  __updateSingleUserCode,
  __createSingleUserCode,
  __deleteSingleUserCode,
};
