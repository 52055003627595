import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import { __getOrganizationUsers } from '../../../../api/organization';

const OrganizationDetail = () => {
  const nav = useNavigate();
  const [rows, setRows] = useState([]);
  const { organization_id } = useParams();
  const [response, call, loading] = useAxios({
    ...__getOrganizationUsers(organization_id),
  });
  const [apiLoading, _] = useState(false);

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setRows(response.data);
    }
  }, [response]);

  if (loading || apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!rows || !Object.keys(rows).length) {
    return (
      <>
        <Title variant="h5" content="Organization Detail" gutterBottom />
        <Box>
          <Stack
            spacing={2}
            direction="row"
            sx={{ justifyContent: 'flex-end' }}
          >
            <Button
              onClick={() => nav('update')}
              variant="contained"
              color="success"
            >
              Update
            </Button>
          </Stack>
          <Box sx={{ m: 2 }}>
            <Divider></Divider>
          </Box>
          <Title content="No Users found" variant="subtitle2" gutterBottom />
        </Box>
      </>
    );
  }

  return (
    <>
      <Title variant="h5" content="Organization Detail" gutterBottom />
      <Box>
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => nav('update')}
            variant="contained"
            color="success"
          >
            Update
          </Button>
        </Stack>
        <Box sx={{ m: 2 }}>
          <Divider></Divider>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Organization</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow
                  key={row.id}
                  component={Link}
                  to={`/user/${row.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{`${row.first_name} ${row.last_name}`}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.organization.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrganizationDetail;
