export const SERVICE_ACTIVATION_DETAIL = {
  activation_id: 'Activation ID',
  billing_account_id: 'Billing Account Id',
  status: 'Activation Status',
  started_time: 'Requested Date',
  completion_time: 'Completed Date',
  profile_check: 'Profile Check(PDP)',
  ip_reservation: 'IP Reservation(PDP)',
  profile_update: 'Profile Update(PDP)',
  pdp_provision: 'PDP Provision(PDP)',
};

export const LINE_DETAIL_LABEL = {
  subscriber_number: 'Subscriber Number',
  status_effective_date: 'Status Effective Date',
  subscriber_activation_date: 'Subscriber Activation Date',
  single_user_code: 'Single User Code',
  billing_account_number: 'Billing Account Number',
  next_bill_cycle_date: 'Next Bill Cycle Date',
  sim: 'Sim',
  imei: 'IMEI',
  status: 'Status',
};

export const SERVICE_ACTIVATION_LIST_LABEL = {
  activation_id: 'Activation ID',
  billing_account_id: 'Billing Account ID',
  imei: 'IMEI',
  sim: 'SIM',
  single_user_code: 'Single User Code',
  first_name: 'First Name',
  last_name: 'Last Name',
  timestamp: 'Date Requested',
};

export const USER_DETAIL_LABEL = {
  id: 'ID',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  organization: 'Organization',
  is_superuser: 'Superuser',
  is_admin: 'Admin',
};

export const ORGANIZATION_MAPPING = {
  att: 'ATT',
  jasper: 'Jasper',
};

export const JASPER_DEVICE_DETAIL_LABEL = {
  iccid: 'ICCID',
  account_id: 'Account Id',
  imei: 'IMEI',
  status: 'Status',
  rate_plan: 'Rate Plan',
  cycle_data_usage: 'Cycle To Date Data Usage (MB)',
  added_date: 'Date Added',
  updated_date: 'Updated Date',
};
