import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';

const SingleUserCodeList = ({ singleUserCodes, deleteCode }) => {
  const [rows, setRows] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    if (singleUserCodes && singleUserCodes.length) {
      setRows(singleUserCodes.map(each => each));
    }
  }, [singleUserCodes]);

  if (!rows.length) {
    return (
      <Box
        sx={{ width: '100%', boxShadow: 1, borderRadius: 1, p: 4 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom component="div">
          Single User Codes not available
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Data</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.data_limit || 0} GB</TableCell>
              <TableCell>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => nav(`${row.id}/update`)}
                  >
                    Update Code
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => deleteCode(row.id)}
                  >
                    Delete Code
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SingleUserCodeList;
