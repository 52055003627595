import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import Title from '../../common/Title';
import useAxios from '../../../hooks/useAxios';
import InputFieldBox from '../../common/InputFieldBox';
import { __activateNewService } from '../../../api/account';
import { __getSingleUserCodes } from '../../../api/singleUserCode';

const ServiceActivationActivate = () => {
  let initialData = {
    billing_account_id: '',
    single_user_code_id: '',
    service_zip_code: '',
    include_packet_data_profile: true,
    imei: '',
    sim: '',
    related_party: {
      first_name: '',
      last_name: '',
      address: {
        street_number: '',
        street_direction: '',
        street_name: '',
        city: '',
        state: '',
        zip_code: '',
      },
    },
  };
  const nav = useNavigate();

  const [requestData, setRequestData] = useState(null);
  const [singleUserCodes, setSingleUserCodes] = useState([]);
  const [singleUserCodeResponse, singleUserCodeCall, apiLoading] = useAxios({
    ...__getSingleUserCodes,
  });

  const addressSchema = yup.object({
    street_number: yup
      .string('Street Number')
      .required('Street Number is required'),
    street_direction: yup
      .string('Street Direction')
      .required('Street Direction is required'),
    street_name: yup.string('Street Name').required('Street Name is required'),
    city: yup.string('City').required('City is required'),
    state: yup.string('State').required('State is required'),
    zip_code: yup.string('Zip Code').required('Zip Code is required'),
  });

  const relatedPartySchema = yup.object({
    first_name: yup.string('First Name').required('First Name is required'),
    last_name: yup.string('Last Name').required('Last Name is required'),
    address: addressSchema,
  });

  const validationSchema = yup.object({
    billing_account_id: yup
      .string('Billing Account Id')
      .required('Billing Account ID is required'),
    single_user_code_id: yup
      .number('Single User Code')
      .required('Single User Code is required'),
    include_packet_data_profile: yup
      .boolean('Include Packet Data Profile')
      .default(false),
    service_zip_code: yup
      .string('Service Zip Code')
      .required('Service Zip Code is required'),
    imei: yup
      .string('IMEI')
      .required('IMEI is required')
      .test(
        'length',
        'Must be exactly 15 characters',
        val => val && val.length && val.length === 15
      ),
    sim: yup.string('SIM').required('SIM is required'),
    related_party: relatedPartySchema,
  });

  const formik = useFormik({
    initialValues: {
      ...initialData,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setRequestData(values);
    },
  });

  const [response, call, loading] = useAxios({
    ...__activateNewService,
    data: requestData,
  });

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      response?.data?.activation_id
        ? nav('/service-activation/' + response.data.activation_id)
        : nav('/service-activation');
    }
    setRequestData(null);
    formik.setSubmitting(false);
  }, [response]);

  useEffect(() => {
    if (requestData) {
      call();
    }
  }, [requestData]);

  useEffect(() => {
    singleUserCodeCall();
  }, []);

  useEffect(() => {
    if (singleUserCodeResponse && singleUserCodeResponse.status) {
      setSingleUserCodes(singleUserCodeResponse.data);
    }
  }, [singleUserCodeResponse]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Title content="Activate new service" variant="title" gutterBottom />
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} sx={{ mb: 3, mt: 2 }}>
          <FormControl
            error={
              formik.touched.billing_account_id &&
              Boolean(formik.errors.billing_account_id)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="billing_account_id">
              Billing Account ID
            </InputLabel>
            <OutlinedInput
              name="billing_account_id"
              label="Billing Account ID"
              id="billing_account_id"
              value={formik.values.billing_account_id}
              onChange={formik.handleChange}
            />
            {formik.touched.billing_account_id &&
            Boolean(formik.errors.billing_account_id) ? (
              <FormHelperText id="billing_account_id-error-text">
                {formik.errors.billing_account_id}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.single_user_code_id &&
              Boolean(formik.errors.single_user_code_id)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputFieldBox
              isSelect
              options={singleUserCodes.map(val => ({
                value: val.id,
                label: `${val.name} - ${val.code}`,
              }))}
              name="single_user_code_id"
              label="Plan"
              id="single_user_code_id"
              value={formik.values.single_user_code_id}
              onChange={formik.handleChange}
            />
            {formik.touched.single_user_code_id &&
            Boolean(formik.errors.single_user_code_id) ? (
              <FormHelperText id="single_user_code_id-error-text">
                {formik.errors.single_user_code_id}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.service_zip_code &&
              Boolean(formik.errors.service_zip_code)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="service_zip_code">Service Zip Code</InputLabel>
            <OutlinedInput
              name="service_zip_code"
              label="Service Zip Code"
              id="service_zip_code"
              value={formik.values.service_zip_code}
              onChange={formik.handleChange}
            />
            {formik.touched.service_zip_code &&
            Boolean(formik.errors.service_zip_code) ? (
              <FormHelperText id="service_zip_code-error-text">
                {formik.errors.service_zip_code}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.imei && Boolean(formik.errors.imei) ? true : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="imei">IMEI</InputLabel>
            <OutlinedInput
              name="imei"
              label="IMEI"
              id="imei"
              value={formik.values.imei}
              onChange={formik.handleChange}
            />
            {formik.touched.imei && Boolean(formik.errors.imei) ? (
              <FormHelperText id="imei-error-text">
                {formik.errors.imei}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched.sim && Boolean(formik.errors.sim) ? true : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="sim">SIM</InputLabel>
            <OutlinedInput
              name="sim"
              label="SIM"
              id="sim"
              value={formik.values.sim}
              onChange={formik.handleChange}
            />
            {formik.touched.sim && Boolean(formik.errors.sim) ? (
              <FormHelperText id="sim-error-text">
                {formik.errors.sim}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControlLabel
            error={
              formik.touched.include_packet_data_profile &&
              formik.errors.include_packet_data_profile
            }
            sx={{ width: '100%' }}
            control={
              <Checkbox
                name="include_packet_data_profile"
                label="Include Packet Data Profile Feature (00REVGENAPEX)"
                id="include_packet_data_profile"
                checked={formik.values.include_packet_data_profile}
                onChange={formik.handleChange}
              />
            }
            label="Include Packet Data Profile Feature (00REVGENAPEX)"
          >
            {formik.touched.include_packet_data_profile &&
            Boolean(formik.errors.include_packet_data_profile) ? (
              <FormHelperText id="include_packet_data_profile-error-text">
                {formik.errors.include_packet_data_profile}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControlLabel>
        </Grid>

        <Grid xs={12} item>
          <Title content="Related Party" variant="title" gutterBottom />
        </Grid>

        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.first_name &&
              Boolean(formik.errors?.related_party?.first_name)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.first_name">
              First Name
            </InputLabel>
            <OutlinedInput
              name="related_party.first_name"
              label="First Name"
              id="related_party.first_name"
              value={formik.values?.related_party?.first_name}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.first_name &&
            Boolean(formik.errors?.related_party?.first_name) ? (
              <FormHelperText id="related_party.first_name-error-text">
                {formik.errors?.related_party?.first_name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.last_name &&
              Boolean(formik.errors?.related_party?.last_name)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.last_name">Last Name</InputLabel>
            <OutlinedInput
              name="related_party.last_name"
              label="Last Name"
              id="related_party.last_name"
              value={formik.values?.related_party?.last_name}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.last_name &&
            Boolean(formik.errors?.related_party?.last_name) ? (
              <FormHelperText id="related_party.last_name-error-text">
                {formik.errors?.related_party?.last_name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.street_number &&
              Boolean(formik.errors?.related_party?.address.street_number)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.street_number">
              Street Number
            </InputLabel>
            <OutlinedInput
              name="related_party.address.street_number"
              label="Street Number"
              id="related_party.address.street_number"
              value={formik.values?.related_party?.address.street_number}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.street_number &&
            Boolean(formik.errors?.related_party?.address.street_number) ? (
              <FormHelperText id="related_party.address.street_number-error-text">
                {formik.errors?.related_party?.address.street_number}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.street_direction &&
              Boolean(formik.errors?.related_party?.address.street_direction)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.street_direction">
              Street Direction
            </InputLabel>
            <OutlinedInput
              name="related_party.address.street_direction"
              label="Street Direction"
              id="related_party.address.street_direction"
              value={formik.values?.related_party?.address.street_direction}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.street_direction &&
            Boolean(formik.errors?.related_party?.address.street_direction) ? (
              <FormHelperText id="related_party.address.street_direction-error-text">
                {formik.errors?.related_party?.address.street_direction}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.street_name &&
              Boolean(formik.errors?.related_party?.address.street_name)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.street_name">
              Street Name
            </InputLabel>
            <OutlinedInput
              name="related_party.address.street_name"
              label="Street Name"
              id="related_party.address.street_name"
              value={formik.values?.related_party?.address.street_name}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.street_name &&
            Boolean(formik.errors?.related_party?.address.street_name) ? (
              <FormHelperText id="related_party.address.street_name-error-text">
                {formik.errors?.related_party?.address.street_name}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.city &&
              Boolean(formik.errors?.related_party?.address.city)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.city">City</InputLabel>
            <OutlinedInput
              name="related_party.address.city"
              label="City"
              id="related_party.address.city"
              value={formik.values?.related_party?.address.city}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.city &&
            Boolean(formik.errors?.related_party?.address.city) ? (
              <FormHelperText id="related_party.address.city-error-text">
                {formik.errors?.related_party?.address.city}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.state &&
              Boolean(formik.errors?.related_party?.address.state)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.state">State</InputLabel>
            <OutlinedInput
              name="related_party.address.state"
              label="State"
              id="related_party.address.state"
              value={formik.values?.related_party?.address.state}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.state &&
            Boolean(formik.errors?.related_party?.address.state) ? (
              <FormHelperText id="related_party.address.state-error-text">
                {formik.errors?.related_party?.address.state}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <FormControl
            error={
              formik.touched?.related_party?.address.zip_code &&
              Boolean(formik.errors?.related_party?.address.zip_code)
                ? true
                : false
            }
            sx={{ width: '100%' }}
          >
            <InputLabel htmlFor="related_party.address.zip_code">
              Zip Code
            </InputLabel>
            <OutlinedInput
              name="related_party.address.zip_code"
              label="Zip Code"
              id="related_party.address.zip_code"
              value={formik.values?.related_party?.address.zip_code}
              onChange={formik.handleChange}
            />
            {formik.touched?.related_party?.address.zip_code &&
            Boolean(formik.errors?.related_party?.address.zip_code) ? (
              <FormHelperText id="related_party.address.zip_code-error-text">
                {formik.errors?.related_party?.address.zip_code}
              </FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
        <Grid justify="flex-end" alignItems="center" item container>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              onClick={() =>
                !apiLoading &&
                !loading &&
                !formik.isSubmitting &&
                formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              size="large"
              onClick={() => nav('/service-activation')}
              loading={loading}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceActivationActivate;
