function setToStorage(key, value) {
  localStorage.setItem(btoa(key), value);
}
function getFromStorage(key) {
  return localStorage.getItem(btoa(key));
}
function clearFromStorage(key) {
  localStorage.removeItem(btoa(key));
}

export { setToStorage, getFromStorage, clearFromStorage };
