import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import SearchBox from './search/SearchBox';
import Title from '../../common/Title';
import AccountList from './list/AccountList';
import SearchView from './search/SearchView';
import useAxios from '../../../hooks/useAxios';
import {
  __getAccountList,
  __getAccountListExport,
  __searchLine,
} from '../../../api/account';
import { Button, Grid } from '@mui/material';
import fileDownload from 'js-file-download';

const Account = () => {
  const [accounts, setAccounts] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState();
  const [response, call, loading] = useAxios({ ...__getAccountList });
  const [exportResponse, exportCall, exportLoading] = useAxios({
    ...__getAccountListExport,
  });
  const [searchResponse, callSearch] = useAxios({
    ...__searchLine,
    params: { search: searchValue },
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setAccounts(response.data);
    }
  }, [response]);

  const clearSearch = () => {
    setSearchValue('');
    setSearchResult();
  };

  const handleSearch = () => {
    callSearch();
  };

  useEffect(() => {
    if (searchResponse && searchResponse.status) {
      setSearchResult(searchResponse.data);
    }
  }, [searchResponse]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `accounts.csv`);
    }
  }, [exportResponse]);

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="Account List" gutterBottom />
      {response && (
        <Grid container spacing={2}>
          <Grid item>
            <Stack sx={{ mb: '5px' }}>
              <SearchBox
                searchValue={searchValue}
                clearSearch={clearSearch}
                handleSubmit={handleSearch}
                changeSearchValue={setSearchValue}
              />
            </Stack>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || exportLoading}
              onClick={() => exportCall({ responseType: 'blob' })}
            >
              <FileDownloadIcon sx={{ mr: '5px' }} /> Export
            </Button>
          </Grid>
        </Grid>
      )}
      {searchResult ? (
        <SearchView subscriberData={searchResult} />
      ) : response ? (
        <AccountList accounts={accounts} />
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default Account;
