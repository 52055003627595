import React from 'react';

import Box from '@mui/material/Box';

import UserForm from '../UserForm';
import Title from '../../../common/Title';

const UserCreate = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="User Create" gutterBottom />
      <UserForm mode="create" />
    </Box>
  );
};

export default UserCreate;
