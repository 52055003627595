import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';

const AccountList = ({ accounts }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (accounts && accounts.length) {
      setRows(accounts.map(each => each));
    }
  }, [accounts]);

  if (!rows.length) {
    return (
      <Box
        sx={{ width: '100%', boxShadow: 1, borderRadius: 1, p: 4 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom component="div">
          Account not available
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Subscriber No.</TableCell>
            <TableCell align="left">Foundation Account No.</TableCell>
            <TableCell align="left">Billing Account No.</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.subscriber_number}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link to={`${row.subscriber_number}`}>
                  <Button size="small">{row.subscriber_number}</Button>
                </Link>
              </TableCell>
              <TableCell>
                {row.foundation_account_number}
              </TableCell>
              <TableCell >{row.billing_account_number}</TableCell>
              <TableCell align="center">
                {row.subscriber_number_status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountList;
