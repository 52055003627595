import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import UserForm from '../UserForm';
import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import { __getUserDetails } from '../../../../api/user';

const UserUpdate = () => {
  const [user, setUser] = useState([]);
  const { user_id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getUserDetails(user_id),
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setUser(response.data);
    }
  }, [response]);

  if (apiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Title variant="h5" content="User Update" gutterBottom />
      <UserForm
        mode="update"
        initialValues={{ ...user, organization_id: user.organization?.id }}
        userId={user.id}
      />
    </Box>
  );
};

export default UserUpdate;
