const __login = {
  method: 'POST',
  url: 'auth/login',
};

const __getAuthData = {
  method: 'GET',
  url: 'auth/me',
};

export { __login, __getAuthData };
