import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { USER_DETAIL_LABEL } from '../../../../constants/responseMapping';

const UserList = ({ users }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (users && users.length) {
      setRows(users.map(each => each));
    }
  }, [users]);

  if (!rows.length) {
    return (
      <Box
        sx={{ width: '100%', boxShadow: 1, borderRadius: 1, p: 4 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom component="div">
          Users not available
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            {Object.keys(USER_DETAIL_LABEL).map(key => (
              <TableCell key={key}>{USER_DETAIL_LABEL[key]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              component={Link}
              to={`${row.id}`}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.keys(USER_DETAIL_LABEL).map(key => {
                if (key === 'organization') {
                  return (
                    <TableCell key={key}>
                      {row.organization?.name || '-'}
                    </TableCell>
                  );
                }
                if (key === 'is_superuser') {
                  return (
                    <TableCell key={key}>
                      {row.is_superuser.toString()}
                    </TableCell>
                  );
                }
                if (key === 'is_admin') {
                  return (
                    <TableCell key={key}>{row.is_admin.toString()}</TableCell>
                  );
                }
                return <TableCell key={key}>{row[key]}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserList;
