import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import TableContainer from '@mui/material/TableContainer';
import FormHelperText from '@mui/material/FormHelperText';

import Title from '../../../common/Title';
import useAxios from '../../../../hooks/useAxios';
import {
  __getRatePlan,
  __updateRatePlan,
  __getDeviceDetail,
} from '../../../../api/jasper';
import InputFieldBox from '../../../common/InputFieldBox';

export default function ChangeRatePlan() {
  const nav = useNavigate();
  let initialData = { rate_plan: '' };
  const [details, setDetails] = useState(null);
  const [ratePlans, setRatePlans] = useState([]);
  const { iccid } = useParams();
  const [deviceResponse, deviceCall, deviceLoading] = useAxios({
    ...__getDeviceDetail(iccid),
  });
  const [ratePlanResponse, ratePlanCall, ratePlanLoading] = useAxios({
    ...__getRatePlan,
  });
  const [formData, setFormData] = useState(null);

  const validationSchema = yup.object({
    rate_plan: yup.string('New Rate Plan').required('Rate plan is required'),
  });

  const formik = useFormik({
    initialValues: {
      ...initialData,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setFormData(values);
    },
  });

  const [updateResponse, updateCall, updateLoading] = useAxios({
    ...__updateRatePlan(iccid),
    data: formData,
  });

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      nav(`/device/${iccid}`);
    }
    setFormData(null);
    formik.setSubmitting(false);
  }, [updateResponse]);

  useEffect(() => {
    if (formData) {
      updateCall();
    }
  }, [formData]);

  useEffect(() => {
    ratePlanCall();
  }, []);

  useEffect(() => {
    deviceCall();
  }, []);

  useEffect(() => {
    if (deviceResponse && deviceResponse.status) {
      setDetails(deviceResponse.data);
    }
    if (ratePlanResponse && ratePlanResponse.status) {
      setRatePlans(ratePlanResponse.data.rate_plans);
    }
  }, [deviceResponse, ratePlanResponse]);

  if (deviceLoading || ratePlanLoading || updateLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  if (!details || !Object.keys(details).length) {
    return (
      <Title content="Data not available" variant="subtitle2" gutterBottom />
    );
  }

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ICCID</TableCell>
                <TableCell align="left">IMEI</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Current Plan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{details.iccid}</TableCell>
                <TableCell>{details.imei}</TableCell>
                <TableCell>{details.status}</TableCell>
                <TableCell>{details.rate_plan}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt="15px">
        <Title
          content="Select new Rate Plan"
          variant="subtitle2"
          gutterBottom
        />
        <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={5} sx={{ mb: 3 }}>
            <FormControl
              error={
                formik.touched.rate_plan && Boolean(formik.errors.rate_plan)
                  ? true
                  : false
              }
              sx={{ width: '100%' }}
            >
              <InputFieldBox
                isSelect
                options={ratePlans.map(ratePlan => ({
                  value: ratePlan.name,
                  label: ratePlan.name,
                }))}
                name="rate_plan"
                label="Rate Plan"
                id="rate_plan"
                value={formik.values.rate_plan}
                onChange={formik.handleChange}
              />
              {formik.touched.rate_plan && Boolean(formik.errors.rate_plan) ? (
                <FormHelperText id="rate_plan-error-text">
                  {formik.errors.rate_plan}
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid justify="flex-end" alignItems="center" container>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              onClick={() =>
                !updateLoading && !formik.isSubmitting && formik.submitForm()
              }
              size="large"
              loading={updateLoading || formik.isSubmitting}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: '5px' }}
              size="large"
              onClick={() => nav(`/device/${iccid}`)}
              loading={updateLoading || formik.isSubmitting}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
