import { useContext } from 'react';

import { AlertContext } from '../context/index';

const useAlert = () => {
  const [alert, alertDispatch] = useContext(AlertContext);

  const setAlertMessage = (data) => {
    alertDispatch({
      type: 'SET_ALERT_MESSAGE',
      payload: data,
    });
  };
  const resetAlertMessage = () => {
    alertDispatch({
      type: 'RESET_ALERT_MESSAGE',
    });
  };

  return { alert, setAlertMessage, resetAlertMessage };
};

export default useAlert;
