import React from 'react';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import Title from '../../../common/Title';

export default function SearchView({ subscriberData }) {
  if (!subscriberData || Object.keys(subscriberData).length === 0) {
    return <Title content="No line found." variant="subtitle2" gutterBottom />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Subscriber No.</TableCell>
            <TableCell>SIM</TableCell>
            <TableCell>IMEI</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            component={Link}
            to={`${subscriberData.subscriber_number}`}
            key={subscriberData.subscriber_number}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="subscriberData">
              {subscriberData.subscriber_number}
            </TableCell>
            <TableCell>{subscriberData.sim}</TableCell>
            <TableCell>{subscriberData.imei}</TableCell>
            <TableCell>{subscriberData.status}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
